export default function Resumen() {
  return (
    <div className="section section--noborder">
      <h3 className="section__title section__title--wide">Día y Horario</h3>
      <p className="section__detail">
        La celebración, tanto la ceremonia como la fiesta, se realizará el{" "}
        <b style={{ textDecoration: "none" }}>27 de enero</b> en el mismo lugar
        a partir de las <b style={{ textDecoration: "none" }}>18:00 horas</b>{" "}
        (puntual)
      </p>
    </div>
  );
}
