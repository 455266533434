import "./App.css";
import { Countdown } from "./Countdown";
import "./arrow.css";
import { useEffect, useMemo, useRef, useState } from "react";
import Lugar from "./Lugar";
import Resumen from "./Resumen";
import CodigoVestimenta from "./CodigoVestimenta";
import Fiesta from "./Fiesta";
import Regalos from "./Regalos";
import Confirmar from "./Confirmar";
import { sample } from "lodash";
function App() {
  return (
    <>
      <Portada />
      <Countdown />
      <Resumen />
      <Lugar />
      <CodigoVestimenta />
      {/* <Fiesta /> */}
      <Regalos />
      <Confirmar />
    </>
  );
}

export default App;

function Portada() {
  const elementoRef = useRef(null);
  const [tamanioNombres, setTamanioNombres] = useState("1.9em");
  const [altura, setAltura] = useState("1.9em");

  useEffect(() => {
    // Obtener la altura del elemento cuando el componente se monta
    if (elementoRef.current) {
      const alturaElemento = elementoRef.current.clientHeight;
      setAltura(alturaElemento);
      if (alturaElemento > 420) {
        setTamanioNombres("1.6em");
      }
    }
  }, [altura]);
  const portadas = useMemo(() => {
    return [
      "var(--imagen-1)",
      "var(--imagen-2)",
      "var(--imagen-3)",
      "var(--imagen-4)",
      "var(--imagen-5)",
      "var(--imagen-6)",
      "var(--imagen-7)",
      "var(--imagen-8)",
      "var(--imagen-9)",
    ];
  }, []);
  return (
    <div
      className="portada"
      style={{
        backgroundImage: sample(portadas),
      }}
    >
      <span className="fecha fecha--short">27 · 01 · 24</span>
      <span className="fecha fecha--anio"> </span>

      <div
        className="portada__nombres"
        style={{ fontSize: tamanioNombres }}
        ref={elementoRef}
      >
        <h1 className="nombre">Luca & Maru</h1>
      </div>
    </div>
  );
}
