import { useState } from "react";
import BotonConfirmacion from "./BotonConfirmacion";

export default function Confirmar() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="section section--noborder">
      <BotonConfirmacion
        onClick={() => {
          openModal();
        }}
      />
      {showModal && (
        <div
          className="modal"
          onClick={() => {
            openModal();
          }}
        >
          <div
            className="modal__content section section--noborder"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="section__title section__detail modal__title">
              ¿A quién le querés confirmar tu asistencia?
            </p>
            <div className="modal__botones">
              <div className="section__boton section__boton--confirm">
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  target={"_blank"}
                  href="https://wa.me/+541165944947?text=¡Hola! Confirmo mi asistencia para la fecha de la boda. ¡Nos vemos allá! ¡Abrazo grande!"
                >
                  Confirma con el novio
                </a>
              </div>
              <div className="section__boton section__boton--confirm">
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  target={"_blank"}
                  href="https://wa.me/+541123964895?text=¡Hola! Confirmo mi asistencia para la fecha de la boda. ¡Nos vemos allá! ¡Abrazo grande!"
                >
                  Confirma con la novia
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
