export default function BotonConfirmacion({ onClick }) {
  return (
    <button
      target="_blank"
      onClick={() => {
        onClick();
      }}
      className="section__boton section__boton--big"
      style={{ textDecoration: "none" }}
    >
      Confirmanos tu asistencia
    </button>
  );
}
