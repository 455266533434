export default function CodigoVestimenta() {
  return (
    <div className="section">
      <h3 className="section__title section__title--wide">Vestimenta</h3>
      <p className="section__detail ">
        Nuestro código de vestimenta es:{" "}
        <b style={{ textDecoration: "none" }}>Elegante</b>.
      </p>
      <p className="section__detail">
        Recomendamos usar zapatos cómodos para disfrutar al máximo de esta
        celebración al aire libre.
      </p>
    </div>
  );
}
