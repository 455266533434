import { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";

export default function Regalos() {
  return (
    <div className="section">
      <h3 className="section__title section__title--wide">Presente</h3>
      <p className="section__detail">
        ¡No hay mejor regalo que celebrar este día con ustedes! Pero, si a la
        buena onda le quieren sumar algún detalle, acá les dejamos nuestros
        datos bancarios:
      </p>
      <p className="section__detail section__detail--align-left">
        <br />
        <b style={{ textDecoration: "none" }}>Alias:</b> lhazuca.hsbc <br />{" "}
        <b style={{ textDecoration: "none" }}>CBU:</b> 1500307600030760416764{" "}
        <FaCopy
          onClick={() => {
            var textArea = document.createElement("textarea");
            textArea.value = "1500307600030760416764";

            // Evitar desplazamiento en dispositivos móviles al enfocar y desenfocar el elemento
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = 0;
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
              var successful = document.execCommand("copy");
            } catch (err) {
              console.error("Error al intentar copiar al portapapeles: ", err);
            }

            document.body.removeChild(textArea);
          }}
        />
        <br />
        Luca Nicolás Hazuca
      </p>
      <br />
      <p className="section__detail section__detail--align-left">
        <b style={{ textDecoration: "none" }}>Alias:</b> mariangeles.bza
        <br /> <b style={{ textDecoration: "none" }}>CVU:</b>{" "}
        0000003100059143352802
        <FaCopy
          style={{ marginLeft: "15px" }}
          onClick={() => {
            var textArea = document.createElement("textarea");
            textArea.value = "0000003100059143352802";

            // Evitar desplazamiento en dispositivos móviles al enfocar y desenfocar el elemento
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = 0;
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
              var successful = document.execCommand("copy");
            } catch (err) {
              console.error("Error al intentar copiar al portapapeles: ", err);
            }

            document.body.removeChild(textArea);
          }}
        />
        <br />
        María de los Angeles Barboza
      </p>
    </div>
  );
}
