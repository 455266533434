import { useState, useEffect } from "react";

export function Countdown() {
  const targetDate = new Date("2024-01-27T15:00:00").getTime(); // Fecha objetivo en formato ISO

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const timeDifference = targetDate - now;

    if (timeDifference <= 0) {
      // La fecha objetivo ya ha pasado
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return (
    <div className="section section--noborder section--grey">
      <h3 className="section__title section__title--white">Faltan</h3>
      <Reloj
        diasFaltantes={timeLeft.days}
        horasFaltantes={timeLeft.hours}
        minutosFaltantes={timeLeft.minutes}
        segundosFaltantes={timeLeft.seconds}
      />
    </div>
  );
}
function Reloj({
  diasFaltantes,
  horasFaltantes,
  minutosFaltantes,
  segundosFaltantes,
}) {
  return (
    <div className="countdown__reloj">
      <div className="countdown__reloj__dias">
        <span className="countdown__reloj__dias__numero">{diasFaltantes}</span>
        <span className="countdown__reloj__dias__unidad">DÍAS</span>
      </div>
      <div className="countdown__reloj__dias">
        <span className="countdown__reloj__dias__numero">
          {(horasFaltantes % 24) + 3}
        </span>
        <span className="countdown__reloj__dias__unidad">HS</span>
      </div>
      <div className="countdown__reloj__dias">
        <span className="countdown__reloj__dias__numero">
          {minutosFaltantes % 60}
        </span>
        <span className="countdown__reloj__dias__unidad">MIN</span>
      </div>
      <div className="countdown__reloj__dias">
        <span className="countdown__reloj__dias__numero">
          {segundosFaltantes % 60}
        </span>
        <span className="countdown__reloj__dias__unidad">SEG</span>
      </div>
    </div>
  );
}
