const Lugar = () => {
  return (
    <div className="section">
      <h3 className="section__title">Dirección</h3>
      <p className="section__detail">Casa quinta "Eben Ezer"</p>
      <p className="section__detail">Calle 616 entre 538 y 539 Nro 3822</p>
      <p className="section__detail">El Pato</p>
      <div
        style={{
          margin: "1em",
          alignContent: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2859.8836333442255!2d-58.17004070194265!3d-34.88312948635992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDUyJzU4LjMiUyA1OMKwMTAnMDkuNSJX!5e0!3m2!1ses-419!2sar!4v1697336992977!5m2!1ses-419!2sar"
          width="800px"
          height="500px"
          style={{ border: "3px solid #876f5a", marginBottom: "2em" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>
      <a
        href="https://maps.app.goo.gl/H9CPuJDqpByD5uUZA"
        target="_blank"
        className="section__boton"
      >
        ¿Cómo llegar?
      </a>
    </div>
  );
};

export default Lugar;
